<template>
	<div class="page" style='position: relative;'>
		<div class='container' style='padding-top: 15px;'>
			<!-- <el-button @click="rotate(90)">旋转</el-button> -->



			<div class="con">
				<div class="box1">
					<div class="title">请将要扫描的文件 放在高拍仪下</div>
					<img id="view1" src="http://127.0.0.1:38088/video=stream&camidx=0" alt="主摄像头">
				</div>
				<div class="box2">
					<div class="btn" @click="fileScan('0')">
						{{ msg }}
					</div>


					<div class="btn" @click="print()">
						打印
					</div>
				</div>
			</div>


			<el-dialog title="参数选择" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
				<div class="dialogCon">
					<div class="box">

						<el-form :model="wxData" :rules="rules" ref="wxData" label-width="100px" class="demo-wxData">
							<el-form-item label="打印数量" prop="name">
								<el-input-number v-model="wxData.print_number" :min="1" @change="sumMoney()"
									label="描述文字">
								</el-input-number>
							</el-form-item>

							<el-form-item label="打印类型">
								<el-radio-group v-model="wxData.paper_type" @change="sumMoney()">
									<el-radio label="1" border>黑白</el-radio>
									<el-radio label="2" border>彩色</el-radio>
									<el-radio label="3" border>黑白（去底色）</el-radio>
								</el-radio-group>
							</el-form-item>



							<el-form-item label="打印类型类型">
								<el-radio-group v-model="wxData.print_ctype" @change="sumMoney()">
									<el-radio label="1" border>单面</el-radio>
									<el-radio label="2" border>双面</el-radio>
								</el-radio-group>
							</el-form-item>


							<el-form-item label="打印规格" prop="print_type">
								<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
									<el-radio label="1" border v-if="model == 2">A3</el-radio>
									<el-radio label="2" border>A4</el-radio>
								</el-radio-group>
							</el-form-item>


							<el-form-item label="是否群打印" v-if="is_enterprise == 1">
								<el-switch v-model="isGroup"></el-switch>
							</el-form-item>

							<div style='height:60px'>
								<el-form-item label="群打印成员" v-if="isGroup">
									<el-checkbox-group v-model="ids">
										<el-checkbox :label="item.id" name="type" v-for="(item,index) in accList">
											{{ item.real_name }}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</div>


							<el-form-item>
								<el-button type="primary" @click="preview()">
									打印预览</el-button>
							</el-form-item>



							<el-form-item label="价格">
								<p>¥ {{ price }}</p>
							</el-form-item>


							<el-form-item v-if="this.$store.state.userinfo">
								<el-button type="success" @click="pay('wx')">微信支付</el-button>
								<el-button type="success" @click="pay('ye',1)">余额支付</el-button>
								<el-button type="success" @click="pay('ye',2)" v-if="is_enterprise == 1">企业支付
								</el-button>
							</el-form-item>

							<el-form-item v-else>
								<el-button type="success" @click="ykpay()">微信支付</el-button>
							</el-form-item>

						</el-form>



						<vue-qr :text="code" :size="200"
							style="margin:0 auto; width:200px; height:200px; margin-top:30px" v-if="iscode"></vue-qr>

					</div>

				</div>

			</el-dialog>


			<el-dialog title="微信扫码支付" :visible.sync="showc" width="20%" :before-close="wxclose"
				style="text-align: center;">

				<vue-qr :text="code" :size="200" style="margin:0 auto;width:200px; height:200px"></vue-qr>

			</el-dialog>



			<el-dialog title="预览" :visible.sync="dialogPic" width="500px">
				<div>
					<img width="450px" :src="`data:imge/jpeg;base64,${pics[currentIndex]}`" alt="">
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="remove">删除</el-button>
					<el-button type="primary" @click="dialogPic = false">关闭</el-button>
				</span>
			</el-dialog>


			<el-dialog :visible.sync="printOk" width="30%">
				<div class="info" style="text-align: center;">
					<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
					<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

					<div v-if="$store.state.userinfo">
						<p style="color:red; font-weight: bold; font-size: 16px; margin-bottom: 20px;">
							为确保您的信息安全，打印完成后记得退出</p>
						<p>
							<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
						</p>
						<p>
							<el-button type="primary" style="width:80%; margin:30px 0" @click="$router.go(0)">继续打印
							</el-button>
						</p>
					</div>
				</div>
			</el-dialog>



			<div class="pics" id="pics">
				<div class="box" v-for="(item,index) in pics" :key="index" @click="show(index)">
					<img :src="`data:imge/jpeg;base64,${item}`" alt="">
				</div>
			</div>
		</div>


		<div class="mask" style="width:100vw; height:100vh; position: absolute; top:-115px;" v-show="loading"
			v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading"></div>



		<Print ref="print"></Print>

	</div>
</template>

<script>
	import {
		getLodop
	} from '../utils/LodopFuncs'

	import Print from "@/components/Print.vue"

	import vueQr from 'vue-qr'

	import axios from 'axios'
	export default {
		components: {
			vueQr,
			Print
		},
		data() {
			return {
				ids: [],
				accList: [],
				pic: require('@/assets/images/code2.jpg'),
				pics2: [],
				pics: [],
				img: '',
				msg: '拍照',
				dialogVisible: false,
				dialogVisible2: false,
				sizeDialogVisible: false,
				file: '',
				print_ctype: 1,
				dialogPic: false,
				iscode: false,
				jobCode: '1', //job码
				printStatus: 1, //打印状态
				loading: false,
				currentIndex: 0,
				timer: null,
				printOk: false,
				page: 'a4',
				isGroup: false,
				rules: {},
				wxData: {
					type: "",
					task_type: '6',
					paper_type: '1',
					print_type: '2', //A4
					print_number: '1',
					print_count: '',
					print_file: '',
					print_hb_file: '',
					print_hb_qds_file: '',
					print_ctype: '1',
					money_type: ''
				},

				sumMoneyData: {
					type: '',
					page: '',
					number: '',
					ctype: '1',
					paper_type: '1'
				},
				price: '',
				showc: false,
				is_enterprise: false,
				model: 0,

				previewLoading: false
			}
		},
		created() {
			this.common.voice('请将要扫描的文件 放在高拍仪下')
			this.model = localStorage.getItem('model')
			if (localStorage.getItem('model') == 2) {
				this.sizeDialogVisible = false;
			}

			if (this.$store.state.userinfo) {
				if (this.$store.state.userinfo.is_enterprise == 1) {
					this.is_enterprise = true
					this.AccountList();
				}

			}
		},


		methods: {


			async AccountList() {
				let query = {
					limit: 1000,
					page: 1
				}
				let res = await this.$api.AccountList(query);
				this.accList = res.data.rows;
				console.log('acc', this.accList)
			},



			//微信支付关闭
			wxclose() {
				clearTimeout(this.timer)
				this.showc = false;
			},


			//预览文件
			preview() {


				this.$refs.print.order(this.wxData, 'previewfile')
				return false;


				this.previewLoading = true;


				let LODOP = getLodop();

				let obj = JSON.parse(JSON.stringify(this.wxData));

				console.log('wd', this.wxData);

				console.log('预览详情', this.wxData);
				this.$refs.print.order(this.wxData, 'previewfile')
				return false;
				//	return false;

				let hb = obj.print_file.split('.');
				hb = hb[0] + '_hb.' + hb[1];


				obj.print_hb_file = this.$domain + hb;

				obj.print_file = this.$domain + obj.print_file


				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}

				//去底色
				if (obj.paper_type == 3) {
					obj.print_file = this.$domain + obj.print_hb_qds_file
				}



				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");



				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);


					if (obj.print_typeset == '1') {
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}


					//扫描文件统一横向处理
					if (obj.task_type == '6' || obj.task_type == '7') {

						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
					}
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);

					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');

					} else { //a4纸张

						if (obj.print_typeset == 1) {
							LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
						} else {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}


						if (obj.task_type == '6' || obj.print_type == '7') {
							LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						}

					}


				}



				//LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", 'https://print.ynlothar.com/uploads/20220423/2761bdaa051788d65b7478316b6b0a037e071fb4.pdf');
				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));



				if (obj.print_ctype == '2') { //双面打印

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				} else {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数

				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");


				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
				//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
				LODOP.On_Return = function(TaskID, Value) {
					// console.log("TaskID:" + TaskID);
					// console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
						// console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.previewLoading = false;


							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};

				LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 1200, 800, "");
				LODOP.PREVIEW();

				return;
			},






			//创建任务
			async saveTask(type) {
				let data = {
					'type': this.wxData.task_type,
					'is_mass': 2,
					'paper_type': this.wxData.paper_type,
					'print_file': this.wxData.print_file,
					'print_hb_file': this.wxData.print_hb_file,
					'print_count': this.wxData.print_count,
					'print_number': this.wxData.print_number,
					'user_data': this.ids,
					'money_type': this.wxData.money_type,
					'print_ctype': this.wxData.print_ctype,
					'print_type': this.wxData.print_type

				}
				console.log('任务参数', data);

				let res = await this.$api.saveTask(data);

				if (res.code == 200) {
					console.log('任务', res)
					let param = {
						'id': res.id,
						'type': type + 1
					}
					this.orderPay(param, res.order_number)
				} else {
					this.$message.error(res.msg)
				}



			},


			//订单支付
			async orderPay(data, order_number) {
				let res = await this.$api.orderPay(data);
				console.log('订单', res)
				if (res.code == 200) {
					this.$message.success(res.msg)

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': order_number,
					}
					this.OrderDetail(param)

				} else {
					this.$message.error(res.msg)
				}
			},



			async sumMoney() {

				/* 	try {
				 */
				this.wxData.print_count = this.pics.length

				this.sumMoneyData.number = this.wxData.print_number;
				this.sumMoneyData.page = this.wxData.print_count;
				this.sumMoneyData.type = this.wxData.print_type;
				this.sumMoneyData.ctype = this.wxData.print_ctype;
				this.sumMoneyData.paper_type = this.wxData.paper_type;

				if (this.sumMoneyData.paper_type == 3) {
					this.sumMoneyData.paper_type = 1;
				}


				if (this.wxData.print_ctype == 2) { //双页打印
					this.sumMoneyData.page = Math.ceil(this.wxData.print_count / 2);
				}

				let res = null;
				if (this.$store.state.userinfo) {
					res = await this.$api.sumMoney(this.sumMoneyData);
				} else {
					res = await this.$api.yksumMoney(this.sumMoneyData);
				}
				this.price = res.data
				this.wxData.money_type = res.name;

			},


			async ykpay() {
				this.wxData.type = 2; //打印支付
				let res = await this.$api.ykscanPay(this.wxData);
				this.code = res.data.code_url;
				console.log('code', this.code)

				this.showc = true;


				this.timer = setInterval(() => {
					this.ykscanPaySuccessful(res.data.order_number, res.data.task_number);
				}, 1000)
			},

			async pay(type, enter = 0) {
				this.dialogVisible = false;
				if (type == 'wx') {
					this.wxData.type = 2; //打印支付
					let res = await this.$api.scanPay(this.wxData);
					this.code = res.data.code_url;
					console.log('code', this.code)

					this.showc = true;


					this.timer = setInterval(() => {
						this.scanPaySuccessful(res.data.order_number, res.data.task_number);
					}, 1000)

				} else {


					this.wxData.ctype = 2; //打印支付
					this.wxData.type = enter; //企业


					//群打印需要先创建任务
					if (this.isGroup) {
						this.saveTask(enter);
						return false;
					}


					console.log('余额支付11', this.wxData)
					let res = await this.$api.balancePayment(this.wxData);

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': res.order_number
					}
					console.log('参数', param)

					this.OrderDetail(param)


				}

			},



			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {


						res.data.print_file = res.data.print_file
						res.data.print_hb_file = res.data.print_hb_file
						res.data.print_hb_qds_file = res.data.print_hb_qds_file
						console.log('订单详情113355', res);
						this.printFile(res.data);

						//this.$refs.print.order(res.data, 'previewfile')


					}


				} catch (err) {
					console.log('err1', err)
					this.$message.error('数据请求失败');
				}
			},



			close() {
				console.log(3333)
				this.$router.go(-1)
			},

			selectPage(size) {
				this.size = size;
				this.sizeDialogVisible = false;
			},




			async fileScan(type) {



				let data = {
					"filepath": "base64",
					"cutpage": "0",
					"camidx": "0",
					"ColorMode": "0",
					"quality": "3",
					"notoken": 'true',
					"deskew": "1",
					"deskewval": "10"
				}



				let {
					data: res
				} = await axios.post("http://127.0.0.1:38088/video=grabimage", JSON.stringify(data));


				data.ColorMode = "4"; // 去底色
				let {
					data: res2
				} = await axios.post("http://127.0.0.1:38088/video=grabimage", JSON.stringify(data));

				console.log('res1', res)
				console.log('res2', res)



				this.pics.push(res.photoBase64);
				this.pics2.push(res2.photoBase64);



			},


			// 旋转

			rotate(angle) {
				let data = {
					"camidx": '0',
					"rotate": String(angle)
				}
				axios.post("http://127.0.0.1:38088/video=rotate", JSON.stringify(data)).then((res) => {
					mylog("旋转" + String(angle) + "度成功")
				})
			},



			show(index) {
				console.log(index)
				this.currentIndex = index;
				this.dialogPic = true;
			},


			//删除图片
			remove() {

				this.$confirm('您确认删除此照片', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.pics.splice(this.currentIndex, 1)
					this.pics2.splice(this.currentIndex, 1)
					this.dialogPic = false;
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},






			handleClose() {
				this.dialogVisible = false
			},


			async scanPaySuccessful(order, task) {



				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				let that = this;
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);

					this.showc = false;
					this.dialogVisible = false;


					let data = {
						'print_file': that.wxData.print_file,
						'print_hb_file': that.wxData.print_hb_file,
						'print_hb_qds_file': that.wxData.print_hb_qds_file,
						'paper_type': that.wxData.paper_type, //黑白
						'print_ctype': that.wxData.print_ctype, //单双面
						'print_number': that.wxData.print_number, //数量
						'task_number': task
					}


					this.$refs.print.order(that.wxData, 'printfile');
					clearTimeout(this.timer)
				}
			},


			//游客支付状态
			async ykscanPaySuccessful(order, task) {
				let res = await this.$api.ykscanPaySuccessful({
					'order_number': order
				});

				let that = this;
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);





					this.showc = false;
					this.dialogVisible = false;
					let data = {
						'print_file': that.wxData.print_file,
						'print_hb_file': that.wxData.print_hb_file,
						'paper_type': that.wxData.paper_type, //黑白
						'print_ctype': that.wxData.print_ctype, //单双面
						'print_number': that.wxData.print_number, //数量
						'task_number': task
					}

					/* this.$refs. */



					//	this.$refs.print.order(that.wxData, 'printfile');

					this.$refs.print.order(that.wxData, 'printfile');

				}
			},



			async print() {
				//console.log('data3',data3)
				if (this.pics.length < 1) {
					this.$message.warning('请先拍照')
					return false;
				}


				const loading = this.$loading({
					lock: true,
					text: '文件处理中',
					color: "#fff",
					fontSize: 100,
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				let data3 = this.pics;

				try {
					let res3 = await this.$api.base64Pdf({
						'color': this.pics,
						'nocolor': this.pics2
					});




					// let hbRes = await this.$api.blackImg({'inputPath':res3.data});
					// console.log(hbRes)


					console.log('pdf', res3);
					this.file = this.$domain + '/' + res3.data;
					this.wxData.print_file = res3.data;
					this.wxData.print_hb_file = res3.data_hb;
					this.wxData.print_hb_qds_file = res3.file_hb_qds;


					if (this.$store.state.userinfo) {
						let param = {
							name: Date.parse(new Date()) + '.pdf',
							url: res3.data,
							file_hb_url: res3.data_hb,
							file_hb_qds_url: res3.file_hb_qds,
							print_type: 6
						}
						this.$api.fileSave(param)
					}
					loading.close()
					this.dialogVisible = true;
					this.sumMoney()
				} catch {
					loading.close()
					this.$message.error('网络连接失败，请重新拍照')

				}



			},


			/* 	//余额支付
			balancePayment() {
				console.log('file', this.file)
				let data4 = {
					ctype: "2",
					money_type: "A4_DOUBLE_FACE_COLOUR",
					paper_type: 2,
					print_count: 1,
					print_ctype: this.print_ctype,
					print_file: this.file,
		            
					print_number: 1,
					print_type: 2,
					task_type: 6,
					type: 1
				}
				console.log('555',data4)

				this.$api.balancePayment(data4).then(res => {
					console.log('res', res)

					let data = {
						'print_file': this.file,
						'print_ctype': this.print_ctype,
						'task_number': res.task_number,
						'print_number': this.wxData.print_number,
						'paper_type': this.wxData.paper_type
					}
					this.printFile(data);

					console.log('');


				});


			},

 */
			printFile(obj) {

				this.$refs.print.order(obj, 'printfile')
				return false;

				let LODOP = getLodop();
				console.log('wd', obj);
				//return false;

				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}

				if (obj.paper_type == 3) {
					obj.print_file = obj.print_hb_qds_file
				}


				this.loading = true;
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");



				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
					LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4 210 x 297 毫米');
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);

					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');
						//console.log('打印A3纸张')

					} else { //a4纸张
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4');
						//console.log('打印A4纸张')
					}


				}



				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(this.$domain + obj.print_file));
				if (obj.print_ctype == '2') { //双面打印
					console.log('设置双面打印222')
					//LODOP.SET_PRINT_MODE("DOUBLE_SIDED_PRINT",true);

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				} else {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
				//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
						console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							self.accomplish(data);

							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				//	LODOP.PRINT();
				LODOP.PRINT();

				return;



			},


			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
			},

			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},

			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.$router.push('/');
			},


			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},






			base64ImgtoFile(dataurl, filename = 'file') {
				let arr = dataurl.split(',')
				let mime = arr[0].match(/:(.*?);/)[1]
				let suffix = mime.split('/')[1]
				let bstr = atob(arr[1])
				let n = bstr.length
				let u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], `${filename}.${suffix}`, {
					type: mime
				})
			},





			kuang() {
				let data = {
					"camidx": "0", // 摄像头索引，0：主头；1：副头
					"open": "1" // 状态，0：关；1：开
				};
				axios.post("http://127.0.0.1:38088/dvideo=cameradeskew", JSON.stringify(data)).then((res) => {
					console.log(res)
				})
			}
		}


	}
</script>

<style scoped lang='scss'>
	.con {
		display: flex;
		justify-content: space-between;
		height: 500px;
		margin-top: 10px;
		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;

		.title {
			font-size: 30px;
			text-align: center;
			margin-top: 10px;
			color: #FFF566;
		}

		.box1 {
			flex: 2;

			img {
				display: block;
				margin: 20px auto 0px;
				width: 400px;
				height: 300px;
			}
		}


		.box2 {
			flex: 1;

			.btn {
				width: 70%;
				height: 60px;
				line-height: 60px;
				background-color: #95DE64;
				text-align: center;
				margin: 0 auto;
				margin-top: 100px;
				font-size: 30px;
				color: #fff;
				cursor: pointer;

			}
		}

	}

	.pics {

		height: 150px;
		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;
		overflow-x: auto;

		img {
			display: block;
			float: left;
			height: 130px;
			width: 100px;
			margin: 10px;
			/* 	background-color: rgba(0,0,0,0.5); */

		}
	}

	.dialogCon {

		img {
			width: 200px;
			margin: 0 auto;
			display: block;

		}

		.btns {
			margin-top: 100px;
		}

	}
</style>
<style>
	.el-loading-spinner .el-loading-text {
		color: #fff !important;
		font-size: 30px !important;
	}

	.el-loading-spinner .el-icon-loading {
		color: #fff !important;
		font-size: 100px !important;
	}
</style>
